<!--
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2023-02-19 21:43:37
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-02-19 23:06:51
-->
<template>
  <el-select
    v-model="shop_id"
    placeholder="选择门店" clearable
    filterable
    @change="shopChange"
  >
    <el-option v-for="item in shop_list" :key="item.id" :label="item.name" :value="item.id"></el-option>
  </el-select>
</template>

<script>
import shop_api from "@/http/shop_api";
export default {
  data() {
    return {
      //选中的
      shop_id: "",
      //
      shop_list: [],
    };
  },
  emits: ["change"],
  props:['clearable'], 
  created() {
    shop_api.get_my_shops().then((res) => {
      if (res.code == 0) {
        this.shop_list = res.data;
      }
    });
  },
  methods: {
    /**
     * 选中更改
     */
    shopChange() {
      this.$emit("change", this.shop_id);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>