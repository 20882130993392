<!--
 * @Description: 客服运营统计
 * @Author: 琢磨先生
 * @Date: 2023-01-05 12:48:16
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-04-03 16:02:37
-->
<template>
  <div v-loading="loading">
    <el-form :inline="true">
      <el-form-item label="">
        <shop-select class="shop_select" @change="shopChange"></shop-select>
      </el-form-item>
      <el-form-item>
        <el-cascader
          class="revenue_store_select"
          :options="huxing_list"
          v-model="query.storeIds"
          clearable
          collapse-tags
          collapse-tags-tooltip
          :props="{ multiple: true }"
          ,
          filterable
          placeholder="房间选择"
          @change="storeChange"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="">
        <el-select
          v-model="query.ota_id"
          class="revenue_ota_select"
          placeholder="选择渠道"
          clearable
          @change="otaChange"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="item in ota_list"
            :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="query">
      <div class="picker_box">
        <!-- <shop-select class="shop_select" @change="shopChange"></shop-select> -->
        <!-- <el-cascader
          class="revenue_store_select"
          :options="huxing_list"
          v-model="query.storeIds"
          clearable
          collapse-tags
          collapse-tags-tooltip
          :props="{ multiple: true,}"
          ,
          filterable
          placeholder="房间选择"
          @change="storeChange"
        ></el-cascader> -->
        <!-- <el-select
          v-model="query.ota_id"
          class="revenue_ota_select"
          placeholder="选择渠道"
          clearable
          @change="otaChange"
        >
          <el-option :label="item.name" :value="item.id" v-for="item in ota_list" :key="item.id"></el-option>
        </el-select> -->
        <ul>
          <li
            :class="i === dateIndex ? 'active' : ''"
            v-for="(item, i) in shortcuts"
            :key="item"
            @click="onDateLiTab(i)"
          >
            {{ item }}
          </li>
        </ul>
        <el-date-picker
          class="revenue_date_picker"
          v-model="dateList"
          value-format="YYYY-MM-DD"
          format="YYYY-MM-DD"
          type="daterange"
          unlink-panels
          @change="pickerChange"
          :clearable="false"
          start-placeholder
          end-placeholder
          :disabled-date="handleDisabledDate"
        />
      </div>
    </div>
    <div class="main_box">
      <div class="header text-16-bold mt-20">营业额概况</div>
      <div class="body statistics">
        <div class="col total">
          <div class="data">
            <div class="label">客房订单总额</div>
            <div class="amount">￥{{ total_model.total_amount }}</div>
            <!-- <div class="percent"></div> -->
          </div>
          <div class="icon">
            <el-image :src="img_total"></el-image>
          </div>
        </div>
        <div class="col">
          <div class="data">
            <div class="label">
              <span>入住率</span>
              <el-tooltip
                content="入住率 = 累计出售间夜数/总可售房间数"
                placement="top"
              >
                <el-icon>
                  <Warning />
                </el-icon>
              </el-tooltip>
            </div>
            <div class="percent">Occ</div>
            <div class="amount">{{ total_model.occupancy_rate }}%</div>
          </div>
          <div class="icon">
            <el-image :src="img_percent"></el-image>
          </div>
        </div>
        <div class="col">
          <div class="data">
            <div class="label">平均房价</div>
            <div class="percent">ADR</div>
            <div class="amount">￥{{ total_model.adv_price }}</div>
          </div>
          <div class="icon">
            <el-image :src="img_avg"></el-image>
          </div>
        </div>
        <div class="col">
          <div class="data">
            <div class="label">累计出售间夜数</div>
            <div class="amount">{{ total_model.room_days }}</div> 
          </div>
          <div class="icon">
            <el-image :src="img_count"></el-image>
          </div>
          <div style="font-size: 12px;position: absolute;bottom: 5px;left: 20px;width: 100%;">
              {{
                total_model.order_type_days_list.map((x) =>x.label + ':' + x.counts).join('、')
              }}
            </div>
        </div>
        <div class="col">
          <div class="data">
            <div class="label">平均每日间夜数</div>
            <div class="amount">{{ total_model.avg_room_days }}</div>
          </div>
          <div class="icon">
            <el-image :src="img_everyday"></el-image>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import date_util from "@/utils/date_util";
import img_total from "@/assets/analysis_total.png";
import add_everyday from "@/assets/analysis_everyday.png";
import img_avg from "@/assets/analysis_avg.png";
import img_count from "@/assets/analysis_count.png";
import img_percent from "@/assets/analysis_percent.png";
import ota_api from "@/http/ota_api";
import ShopSelect from "@/views/components/shop_select.vue";

export default {
  components: {
    ShopSelect,
  },
  data() {
    return {
      dateIndex: "",
      loading: false,
      dateList: [],
      shortcuts: ["昨日", "今日", "上周", "本周", "上月", "本月"],
      //总计对象
      total_model: {
        total: {},
        room_fee: {},
        penal_sum: {},
        consume: {},
        order_type_days_list:[],
      },
      img_everyday: add_everyday,
      img_avg: img_avg,
      img_count: img_count,
      img_percent: img_percent,
      img_total: img_total,
      //
      query: {},
      ota_list: [],
      huxing_list: [],
    };
  },
  created() {
    ota_api.get_otas().then((res) => {
      if (res.code == 0) {
        this.ota_list = res.data;
      }
    });
    this.loadStore();
    this.onDateLiTab(5);
  },
  methods: {
    /**
     * 快速日期选择切换
     */
    onDateLiTab(i) {
      this.dateIndex = i;
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth();
      var day = date.getDate();
      date = new Date(year, month, day);
      //默认当前月
      var start = new Date(year, month, 1);
      var end = new Date(year, month, day);
      var weekday = date.getDay();
      if (i == 0) {
        //昨日
        start = new Date(date.getTime() - 3600 * 24 * 1000);
        end = start;
      }
      if (i == 1) {
        //今日
        start = end;
      }
      if (i == 2) {
        //上周
        start = new Date(new Date(date).setDate(date.getDate() - weekday - 6));
        end = new Date(new Date(date).setDate(date.getDate() - weekday));
      }
      if (i == 3) {
        //本周
        start = new Date(new Date(date).setDate(date.getDate() - weekday + 1));
      }
      if (i == 4) {
        //上个月
        var x = new Date(new Date(start).setMonth(start.getMonth() - 1));
        end = new Date(new Date(start).setDate(new Date(start).getDate() - 1));
        start = x;
      }

      this.dateList = [date_util.formatDate(start), date_util.formatDate(end)];
      this.loadData();
    },

    /**
     * 日期选择更改
     */
    pickerChange() {
      this.dateIndex = "";
      this.loadData();
    },

    /**
     * 禁用一年后的日期
     */
    handleDisabledDate(date) {
      if (this.dateList.length > 1) {
        var start = new Date(this.dateList[0]);
        var time = start.setFullYear(start.getFullYear() + 1);
        if (date.getTime() > time) {
          return true;
        }
      }
    },
    /**
     * 渠道选择
     */
    otaChange() {
      this.loadData();
    },
    /**
     * 门店选择
     * @param {*} val
     */
    shopChange(val) {
      this.query.shop_id = val;
      this.query.store_ids = [];
      this.loadStore();
      this.loadData();
    },

    /**
     * 房间选中更改
     * @param {*} val
     */
    storeChange(val) {
      this.query.store_ids = [];
      if (val) {
        this.query.store_ids = val.map((x) => {
          return x[1];
        });
      }
      console.log(this.query);
      this.loadData();
    },

    /**
     * 加载门店的房间
     */
    loadStore() {
      this.$http
        .get(
          "seller/v1/houseStore/shop/stores?shopId=" +
            (this.query.shop_id ? this.query.shop_id : 0)
        )
        .then((res) => {
          if (res.code == 0) {
            this.huxing_list = [];
            res.data.forEach((x) => {
              var model = {
                value: x.id,
                label: x.name,
                children: [],
              };
              x.store_list.forEach((item) => {
                model.children.push({
                  value: item.id,
                  label: item.house_no,
                });
              });
              this.huxing_list.push(model);
            });
          }
        });
    },

    /**
     * 加载数据
     */
    loadData() {
      this.loading = true;
      this.$http
        .post("seller/v1/analysis/room/statistics", {
          start_date: this.dateList[0],
          end_date: this.dateList[1],
          ota_id: this.query.ota_id,
          shop_id: this.query.shop_id,
          store_ids: this.query.store_ids,
        })
        .then((res) => {
          if (res.code == 0) {
            this.total_model = res.data;
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style scoped>
.text-16-bold {
  font-size: 16px;
  font-weight: 700;
}
.mt-20 {
  margin-top: 20px;
}
.query {
  display: flex;
  align-items: center;
}
.picker_box {
  display: flex;
  background: white;
  align-items: center;
  border-radius: var(--el-border-radius-base);
  /* border: 1px solid var(--el-border-color); */
  box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color));
  overflow: hidden;
  height: 32px;
}
.picker_box ul {
  list-style: none;
  display: flex;
  align-items: center;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  height: 100%;
  border-right: 1px solid var(--el-border-color);
}
.picker_box ul li {
  list-style: none;
  padding: 0 10px;
  cursor: pointer;
}
.picker_box ul li:hover {
  color: #409eff;
}
.picker_box ul li.active {
  font-weight: 600;
  color: #409eff;
}
/* 概览数据 */

.statistics {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.statistics .col {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 20%;
  padding: 20px;
  background: white;
  margin-right: 20px;
  border-radius: 5px;
  position: relative;
}
.statistics .col:last-child {
  margin-right: 0;
}

.statistics .col.total {
  flex-grow: 2;
}
.statistics .col .data {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.statistics .col .data .label {
  color: #999;
  display: flex;
  align-items: center;
}
.statistics .col .data .label .el-icon {
  margin-left: 5px;
}
.statistics .col .data .amount {
  font-size: 26px;
  font-weight: 700;
}
.statistics .col .data .percent {
  font-size: 12px;
  color: #999;
}

.statistics .icon .el-image {
  width: 50px;
  height: 50px;
  opacity: 0.3;
}
</style>
<style>
.revenue_date_picker.el-input__wrapper,
.revenue_ota_select .el-input__wrapper,
.shop_select .el-input__wrapper,
.revenue_store_select .el-input__wrapper {
  box-shadow: none !important;
  border-radius: 0;
}
</style>
